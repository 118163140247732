import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

//animation
import { motion } from 'framer-motion'
import { titleAnim, fade } from '../animation.js'
import Wave from './svgs/Wave.js'

export const AboutSection = () => {
    return (
        <About>
            <Description>
                <motion.div>
                    <Hide>
                        <motion.h2 variants={titleAnim}>WELCOME TO</motion.h2>
                    </Hide>
                    <Hide>
                        <motion.h2 variants={titleAnim}>
                            JOHN OSTE ENGINEERING <span>CERTIFICATION</span>
                        </motion.h2>
                    </Hide>
                    <Hide>
                        <motion.h2 variants={titleAnim}>SERVICE</motion.h2>
                    </Hide>
                </motion.div>
                <motion.p variants={fade}>
                    To find out more about John’s services, or make a booking, give him a call today.
                </motion.p>
                <Link to="/contact">
                    <motion.button variants={fade}>Contact Us</motion.button>
                </Link>
            </Description>
            <Wave />
        </About>
    )
}
const About = styled.div`
    min-height: 50vh;
    display: flex;
    align-items: center;

    padding: 5rem 10rem;
    color: white;
    @media (max-width: 1300px) {
        display: block;
        padding: 2rem 2rem;
        text-align: center;
    }
`

const Description = styled.div`
    flex: 1;
    padding-right: 2rem;
    text-align: center;
    h2 {
        font-weight: lighter;
    }
    @media (max-width: 1300px) {
        padding: 0;
        button {
            margin: 2rem 0rem 5rem 0rem;
        }
    }
    @media (max-width: 750px) {
        h2 {
            font-size: 24px;
        }
    }
`

const Image = styled.div`
    flex: 1;
    overflow: hidden;
    img {
        width: 100%;
        height: 80vh;
        object-fit: cover;
    }
`

const Hide = styled.div`
    overflow: hidden;
`
