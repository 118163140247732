import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const Wave2 = () => {
    return (
        <WaveSvg viewBox="0 0 1440 258" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_47_263)">
                <motion.path
                    initial={{
                        pathLength: 0,
                        pathOffset: 1,
                    }}
                    animate={{
                        pathLength: 1,
                        pathOffset: 0,
                    }}
                    d="M2 166.5C33 -39.4998 508.5 -57.3618 741 166.5C973.5 390.362 1443 63.9997 1443 63.9997"
                    stroke="#34C1ED"
                    strokeWidth="10"
                    strokeLinecap="square"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_47_263"
                    x="-7.68839"
                    y="0.232422"
                    width="1461.65"
                    height="257.722"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_47_263" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_47_263"
                        result="shape"
                    />
                </filter>
            </defs>
        </WaveSvg>
    )
}

const WaveSvg = styled.svg`
    position: absolute;
    left: 0;
    z-index: 1;
`

export default Wave2
