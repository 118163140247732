import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

//animation
import { motion } from 'framer-motion'
import { pageAnimation, fade, photoAnim, lineAnim, slider, sliderContainder } from '../animation.js'
import Footer from '../components/Footer.js'

const OurWork = ({ services, setServices }) => {
    return (
        <>
            <Work variants={pageAnimation} initial="hidden" animate="show" exit="exit">
                <motion.div variants={sliderContainder}>
                    <Frame1 variants={slider}></Frame1>
                </motion.div>
                {services.map((service) => {
                    return (
                        <Services
                            variants={fade}
                            initial="hidden"
                            whileInView="show"
                            viewport={{ once: true }}
                            exit="exit"
                            key={service.title}
                        >
                            <motion.h2 variants={fade}>{service.title}</motion.h2>
                            <motion.div variants={lineAnim} className="line"></motion.div>
                            <Link className="link" to={service.url}>
                                <motion.img variants={photoAnim} src={service.mainImg} alt="athlete" />
                            </Link>
                        </Services>
                    )
                })}
            </Work>
            <Footer />
        </>
    )
}

const Work = styled(motion.div)`
    min-height: 100vh;
    overflow: hidden;
    padding: 5rem 10rem;
    h2 {
        color: white;
        padding: 1rem 0rem;
    }
    @media (max-width: 1300px) {
        padding: 2rem 2rem;
        h2 {
            text-align: center;
        }
    }
    @media (max-width: 1000px) {
        h2 {
            font-size: 32px;
        }
    }
    @media (max-width: 530px) {
        h2 {
            font-size: 20px;
        }
    }
`

const Services = styled(motion.div)`
    padding-bottom: 10rem;
    .line {
        height: 0.5rem;
        background: #23d997;
        margin-bottom: 3rem;
    }
    img {
        width: 100%;
        height: 70vh;
        object-fit: cover;
    }
    @media (max-width: 1300px) {
        img {
            height: 50vh;
        }
    }
`

//frame animation
const Frame1 = styled(motion.div)`
    position: fixed;
    left: 0;
    top: 10%;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 2;
`
// const Frame2 = styled(Frame1)`
//     background: #ff8efb;
// `
// const Frame3 = styled(Frame1)`
//     background: #8ed2ff;
// `
// const Frame4 = styled(Frame1)`
//     background: #8effa0;
// `

export default OurWork
