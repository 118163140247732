import React, { useState } from 'react'
import GlobalStyle from './components/GlobalStyle'
import { ServiceState } from './servicesState.js'

import UAParser from 'ua-parser-js'
// pages
import Nav from './components/Nav.js'
import Home from './pages/Home'
import AboutUs from './pages/AboutUs'
import ServiceArea from './pages/ServiceArea'
import OurWork from './pages/OurWork'
import ContactUs from './pages/ContactUs'

// Router
import { Routes, Route, useLocation } from 'react-router-dom'

// Animation
import { AnimatePresence } from 'framer-motion'
import ServiceDetail from './pages/ServiceDetail'

function App({ deviceType }) {
    const [services, setServices] = useState(ServiceState)

    const url = useLocation()

    return (
        <div className="App">
            <GlobalStyle />
            <Nav />
            <AnimatePresence
                exitBeforeEnter
                onExitComplete={() => {
                    window.scrollTo(0, 0)
                }}
            >
                <Routes location={url} key={url.pathname}>
                    <Route path="/" exact element={<Home deviceType={deviceType} />} />
                    <Route path="/aboutus" exact element={<AboutUs />} />
                    <Route
                        path="/services"
                        exact
                        element={<OurWork services={services} setServices={setServices} />}
                    />
                    <Route
                        path="/services/:id"
                        exact
                        element={<ServiceDetail services={services} setServices={setServices} />}
                    />
                    <Route path="/service-area" element={<ServiceArea />} />
                    <Route path="/contact" exact element={<ContactUs />} />
                </Routes>
            </AnimatePresence>
        </div>
    )
}
App.getInitialProps = ({ req }) => {
    let userAgent
    if (req) {
        userAgent = req.headers['user-agent']
    } else {
        userAgent = navigator.userAgent
    }
    const parser = new UAParser()
    parser.setUA(userAgent)
    const result = parser.getResult()
    const deviceType = (result.device && result.device.type) || 'desktop'
    return { deviceType }
}

export default App
