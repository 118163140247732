import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Slider from 'react-slick'
//img
import heavy from '../img/heavy.png'
import light from '../img/light.png'
import campervan from '../img/campervan.png'
import trailers from '../img/trailers.png'
import defect from '../img/defect.png'

const settings = {
    className: 'slider',
    dots: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: true,
                speed: 3000,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 980,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: true,
                speed: 3000,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 650,
            settings: {
                autoplay: true,
                speed: 3000,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
            },
        },
    ],
}
export const ServiceSection = ({ deviceType }) => {
    return (
        <>
            <Container>
                <h1>
                    <span>Services</span>
                </h1>
                <Slider {...settings}>
                    <Card>
                        <h3>Heavy Vehicles</h3>
                        <Link className="childLink" to="/services/heavy-vehicle">
                            <img src={heavy} alt="" />
                        </Link>
                    </Card>
                    <Card>
                        <h3>Light Vehicles</h3>
                        <Link className="childLink" to="/services/light-vehicle">
                            <img src={light} alt="" />
                        </Link>
                    </Card>
                    <Card>
                        <h3>Campervan / Motorhome Conversions</h3>
                        <Link className="childLink" to="/services/campervan-motorhome-conversions">
                            <img src={campervan} alt="" />
                        </Link>
                    </Card>
                    <Card>
                        <h3>Trailers / Caravans / ‘’A” Frame Towing </h3>
                        <Link className="childLink" to="/services/trailers-caravans-aframetowing">
                            <img src={trailers} alt="" />
                        </Link>
                    </Card>
                    <Card>
                        <h3>Defects Clearance</h3>
                        <Link className="childLink" to="/services/defect-clerance">
                            <img src={defect} alt="" />
                        </Link>
                    </Card>
                </Slider>
            </Container>
        </>
    )
}
const Container = styled.div`
    display: block;
    min-height: 50vh;
    margin: 4rem 0;
    padding: 0 40px;
    h1 {
        font-size: 3rem;
        text-align: center;
        padding: 2rem;
    }
    .slick-slide img {
        margin: auto;
    }
`
const Card = styled.div`
    display: flex;
    justify-content: center;
    border-radius: 10px;

    cursor: pointer;
    text-align: center;
    h3 {
        top: 110px;
        font-size: 14px;
    }
    img {
        width: 300px;
        height: 500px;
        background: white;
        object-fit: contain;
        border-radius: 15px;
        z-index: 1;
        transform: scale(0.8);
        transition: all ease 1s;
    }
    :hover {
        img {
            box-shadow: 10px 10px #5387b8;
            transform: scale(0.9);
        }
    }
`
