import React from 'react'
//page components
import Banner from '../components/Banner.js'
import { ServiceSection } from '../components/ServiceSection.js'
import { pageAnimation } from '../animation.js'
import AboutSubSection from '../components/AboutSubSection.js'
import { ContactSection } from '../components/ContactSection.js'
import Footer from '../components/Footer.js'

//animation
import { motion } from 'framer-motion'

const Home = ({ deviceType }) => {
    return (
        <motion.div variants={pageAnimation} initial="hidden" animate="show" exit="exit">
            <Banner />
            <ServiceSection deviceType={deviceType} />
            <AboutSubSection />
            <ContactSection />
            <Footer />
        </motion.div>
    )
}
export default Home
