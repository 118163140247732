import React from 'react'
import styled from 'styled-components'
import Toggle from './Toggle.js'

//animation
import { motion, AnimateSharedLayout } from 'framer-motion'
import { scrollReveal } from '../animation.js'
import { useScroll } from './useScroll.js'
import { Link } from 'react-router-dom'

const questions = [
    {
        key: 1,
        linkTo: '/services/heavy-vehicle',
        title: 'Heavy Vehicles',
        answer1: 'Over 4.5T GVM',
        answer2:
            'Heavy Vehicles in Australia that are more than 4.5T GVM must now operate under the National Heavy Vehicle Regulator laws and guidelines. The NHVR uses the Australian Design Rules (ADR’s) and the National Code of Practice VSB 6 as its endorsed guidelines for vehicle modifications.',
    },
    {
        key: 2,
        linkTo: '/services/light-vehicle',
        title: 'Light Vehicles',
        answer1: 'Under 4.5T GVM',
        answer2:
            'Light Vehicles in NSW that have been modified from a standard vehicle will require certification and engineering in accordance with the VSCCS Vehicle Safety Compliance Certification Scheme for registration. The Australian Design Rules (ADR’s) and VSB 14 are the endorsed laws and guidelines for vehicle modifications.',
    },
    {
        key: 3,
        linkTo: '/services/campervan-motorhome-conversions',
        title: 'Campervan / Motorhome Conversions',
        answer1: '',
        answer2:
            'The conversion of your van, bus or any other vehicle into a campervan or motorhome will require certification and engineering in accordance with the VSCCS Vehicle Safety Compliance Certification Scheme for registration. Light Vehicles, vehicles under 4.5T: The Australian Design Rules (ADR’s) and VSB 14 are the endorsed laws and guidelines for vehicle modifications Heavy Vehicles, vehicles over 4.5T: The NHVR uses the National Code of Practice VSB 6 as its endorsed guidelines for vehicle modifications.',
    },
    {
        key: 4,
        linkTo: '/services/trailers-caravans-aframetowing',
        title: 'Trailers / Caravans / ‘’A” Frame Towing ',
        answer1: '',
        answer2:
            'The modification of all trailers and caravans in NSW will require certification and engineering in accordance with the VSCCS Vehicle Safety Compliance Certification Scheme for registration. ',
    },
    {
        key: 5,
        linkTo: '/services/defect-clerance',
        title: 'Defects Clearance',
        answer1: '',
        answer2:
            'John Oste Engineering can offer assistance and advice when your vehicle has been defected. I can assist in assessing the vehicle and providing a Corrective Measures report. After implementation of required corrective measures, I can provide the relevant compliance and paperwork required to clear the defect and have you back on the road again. ',
    },
]

export const FaqSection = () => {
    const [element, controls] = useScroll()
    return (
        <Faq variants={scrollReveal} animate={controls} initial="hidden" ref={element}>
            <h2>
                <span>Services</span>
            </h2>
            <AnimateSharedLayout>
                {questions.map((question) => {
                    return (
                        <Toggle title={question.title} key={question.key}>
                            <div className="answer">
                                <p>{question.answer1}</p>
                                <p>
                                    {question.answer2}
                                    <Link to={question.linkTo}>
                                        <span>Read More</span>
                                    </Link>
                                </p>
                            </div>
                        </Toggle>
                    )
                })}
            </AnimateSharedLayout>
        </Faq>
    )
}

const Faq = styled(motion.div)`
    min-height: 90vh;
    align-items: center;
    justify-content: space-between;
    padding: 5rem 10rem;
    color: white;
    display: block;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    span {
        display: block;
    }
    h2 {
        padding-bottom: 2rem;
        font-weight: lighter;
    }
    h4 {
        cursor: pointer;
    }
    .faq-line {
        background: #cccccc;
        height: 0.2rem;
        margin: 2rem 0rem;
        width: 100%;
    }
    .question {
        padding: 3rem 0rem;
        p {
            padding: 1rem 0rem;
        }
    }
    @media (max-width: 500px) {
        padding: 5rem;
    }
`
