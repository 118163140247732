import React from 'react'
//Styles
import styled from 'styled-components'

//animation
import { motion } from 'framer-motion'
import { scrollReveal } from '../animation.js'
import { useScroll } from './useScroll.js'

export const ServicesAreaSection = () => {
    const [element, control] = useScroll()
    return (
        <Services
            variants={scrollReveal}
            animate={control}
            initial="hidden"
            viewport={{ once: true }}
            ref={element}
        >
            <SeviceDescription>
                <h2>
                    <span>Service</span> area
                </h2>
                <Details>
                    <p>
                        Blacktown, Blue Mountains, Camden, Campbelltown, Canterbury Bankstown, Fairfield,
                        Georges River, Hawkesbury, Hornsby, Inner West Sydney, Kuring Gai, Lane Cove,
                        Liverpool, Northern Beaches, Parramatta, Penrith, Randwick, Ryde, Strathfield,
                        Sutherland, The Hills, Waverley, Wollondilly, Woollahra.
                    </p>
                    <p>or anywhere else in Wollongong, Southern Highlands and the Central Coast</p>
                </Details>
            </SeviceDescription>
        </Services>
    )
}

const Services = styled(motion.div)`
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 10rem;
    color: white;
    h2 {
        padding-bottom: 5rem;
    }

    @media (max-width: 1300px) {
        display: grid;
        grid-template-areas:
            'header header header header  '
            'cards cards cards cards'
            'image image image image';
        gap: 10px;
        padding: 2rem 2rem;
        text-align: center;
    }
`

const SeviceDescription = styled.div`
    flex: 1;
    padding-right: 0;
    h2 {
        font-weight: lighter;
    }
    @media (max-width: 1300px) {
        grid-area: header;
    }
`

const Details = styled.div`
    display: flex;
    flex-wrap: wrap;
    p {
        padding: 24px;
    }
    @media (max-width: 1300px) {
        justify-content: center;
    }
`
