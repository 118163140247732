import React from 'react'
import styled from 'styled-components'
import bannerImg from '../img/BannerImg.jpg'
import { Link } from 'react-router-dom'
//animation
import { motion } from 'framer-motion'
import { fade } from '../animation.js'

const Banner = () => {
    return (
        <BannerTag>
            <img src={bannerImg} alt="" />
            <div className="bannerText">
                <h2>John Oste Engineering</h2>
                <p>
                    VSCCS Licence No <span>160002</span>
                </p>
            </div>

            <Description>
                <Link to="/contact">
                    <motion.button variants={fade}>Contact Me</motion.button>
                </Link>
                <Link className="childLink" to="/services">
                    <motion.button variants={fade}>VSCCS Inspection</motion.button>
                </Link>
            </Description>
        </BannerTag>
    )
}

const BannerTag = styled.div`
    position: relative;  /* Add this line */
    height: 75vh;  /* Add this line to give it height */
    object-fit: contain;
    img {
        position: absolute;
        height: 75vh;
        width: 100%;
        object-fit: cover;
        top: 0;  /* Add this line */
        left: 0;  /* Add this line */
        z-index: 0;  /* Add or adjust this line */
    }
    .bannerText {
        position: absolute;  /* Add this line */
        top: 0;  /* Add this line */
        left: 0;  /* Add this line */
        background-color: transparent;
        z-index: 1;  /* Add or adjust this line */
        height: 75vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
            color: white;
            text-shadow: 2px 2px black;
        }
    }
`

const Description = styled.div`
  position: absolute;  /* Add this line */
  bottom: 0;  /* Add this line */
  width: 100%;  /* Add this line */
  display: flex;
  justify-content: center;
  z-index: 2;  /* Make sure it appears on top */
  a {
    z-index: 2;
  }
  button {
    margin: 2rem;
    z-index: 2;
  }
`;


export default Banner
