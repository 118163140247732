//Import Images

import heavyTruck from './img/truckBig.jpg'
import lightVehicle from './img/lightVehicle.jpg'
import caravan from './img/caravan.jpg'
// import campervan from './img/campervan.jpg'
import campervan2 from './img/campervan2.jpg'
import defect from './img/defect.jpg'

//heavy
import heavy1 from './img/gallery/heavy/heavy1.JPG'
import heavy2 from './img/gallery/heavy/heavy2.JPG'
import heavy3 from './img/gallery/heavy/heavy3.JPG'
import heavy4 from './img/gallery/heavy/heavy4.JPG'
import heavy5 from './img/gallery/heavy/heavy5.JPG'
import heavy6 from './img/gallery/heavy/heavy6.JPG'

//light
import light1 from './img/gallery/light/light1.JPG'
import light2 from './img/gallery/light/light2.JPG'
import light3 from './img/gallery/light/light3.JPG'
import light4 from './img/gallery/light/light4.JPG'
import light5 from './img/gallery/light/light5.JPG'
import light6 from './img/gallery/light/light6.JPG'
import light7 from './img/gallery/light/light7.JPG'
import light8 from './img/gallery/light/light8.JPG'

//motorhome
import motorhome1 from './img/gallery/motorhome/motorhome1.JPG'
import motorhome2 from './img/gallery/motorhome/motorhome2.JPG'
import motorhome3 from './img/gallery/motorhome/motorhome3.JPG'
import motorhome4 from './img/gallery/motorhome/motorhome4.JPG'
import motorhome5 from './img/gallery/motorhome/motorhome5.JPG'
import motorhome6 from './img/gallery/motorhome/motorhome6.JPG'
import motorhome7 from './img/gallery/motorhome/motorhome7.JPG'

//trailer
import trailer1 from './img/gallery/trailer/trailer1.JPG'
import trailer2 from './img/gallery/trailer/trailer2.JPG'
import trailer3 from './img/gallery/trailer/trailer3.JPG'
import trailer4 from './img/gallery/trailer/trailer4.JPG'
import trailer5 from './img/gallery/trailer/trailer5.JPG'
import trailer6 from './img/gallery/trailer/trailer6.JPG'
import trailer7 from './img/gallery/trailer/trailer7.JPG'

//defect
import defect1 from './img/gallery/defect/defect1.JPG'
import defect2 from './img/gallery/defect/defect2.JPG'
import defect3 from './img/gallery/defect/defect3.JPG'
import defect4 from './img/gallery/defect/defect4.JPG'
import defect5 from './img/gallery/defect/defect5.JPG'
import defect6 from './img/gallery/defect/defect6.JPG'

export const ServiceState = () => {
    return [
        {
            title: 'Heavy Vehicles Over 4.5T GVM',
            mainImg: heavyTruck,
            gallery: [heavy1, heavy2, heavy3, heavy4, heavy5, heavy6],
            url: '/services/heavy-vehicle',
            modifications: [
                {
                    title: 'Typical Modifications',
                    description: [
                        ' Beaver Body',
                        ' Brake system Upgrades',
                        ' Cabin Conversions',
                        ' Chassis Extension / Reduction',
                        ' Crane & Goods Loading Device Installation',
                        ' Dual Steering Controls',
                        ' Exhaust System Alteration and Installation',
                        ' Fifth wheel turntables',
                        ' Fuel Tank Alterations',
                        ' GVM Modifications',
                        ' Pantechnicon Body',
                        ' Rear Axle Installation / Additional Rear Axle Installation',
                        ' Replacement Engine Installation',
                        ' Replacement Transmission Installation',
                        ' Slide Tilt Bed Body',
                        ' Steering Modifications',
                        ' Suspension Replacement',
                        ' Tanker Body Modifications',
                        ' Tipper Body Modifications',
                        ' Tow Trucks',
                        ' Tray Body',
                        ' Turbocharger Installation',
                        ' Wheelbase Extension / Reduction',
                    ],
                },
            ],
        },
        {
            title: 'Light Vehicles Under 4.5T GVM',
            mainImg: lightVehicle,
            gallery: [light1, light2, light3, light4, light5, light6, light7, light8],
            url: '/services/light-vehicle',
            modifications: [
                {
                    title: 'Light Vehicles Under 4.5T GVM',
                    description: [
                        '4WD Modifications',
                        'Body Modifications',
                        'Brake System Upgrades',
                        'Crane & Goods Loading Device Installation',
                        'Electric Vehicle Modifications',
                        'Engine Modifications',
                        'Gearbox and Differential Swaps',
                        'GVM Modifications',
                        'Left Hand Drive to Right Hand Drive Conversions',
                        'Performance Upgrades',
                        'Roll Cage Installation',
                        'Seating Capacity Reduction and Increase',
                        'Seats and Seat Belts',
                        'Suspension Modifications',
                        'Turbo’s and Superchargers',
                        'Twin Cab Conversion Modifications',
                        'Wheel, Rim and Tyre size Changes',
                        'Wheelchair Loaders & Mobility Modification',
                    ],
                },
            ],
        },
        {
            title: 'Campervan / Motorhome Conversions',
            mainImg: caravan,
            gallery: [motorhome1, motorhome2, motorhome3, motorhome4, motorhome5, motorhome6, motorhome7],
            url: '/services/campervan-motorhome-conversions',
            modifications: [
                {
                    title: 'Typical Modifications',
                    description: [
                        'Basic motorhome/Campervan Conversion (Minimum Motorhome Requirements)',
                        'Bench tops, Cupboard, Table & Chairs & Interior fitting Installation,',
                        'Electricity installation.',
                        'Gas system installation',
                        'Number of berths',
                        'Toilet Sink & Shower Installations',
                        'Vehicle Mass Ruling GVM capacity of vehicle',
                        'Ventilation Requirements',
                        'Water Tanks, Fresh & Grey Installation',
                        'Window / Glazing Modifications ',
                    ],
                },
                {
                    title: 'Advice on Statutory Requirements',
                    description: [
                        'Electrical, Solar panels & Solar Generated Power System Requirements',
                        'Fire safety Equipment Requirements',
                        'General Safety Requirements',
                        'LPG Gas Installations',
                        'Personal Effects Allowance Requirements',
                        'Tare weight of vehicle; eg, having enough capacity to be a Camper/Motorhome, allowing for your Camper/Motorhome items',
                        'Vehicle Mass Ruling (GVM capacity of vehicle) ',
                    ],
                },
            ],
        },
        {
            title: 'Trailers / Caravans / ‘’A” Frame Towing',
            mainImg: campervan2,
            gallery: [trailer1, trailer2, trailer3, trailer4, trailer5, trailer6, trailer7],
            url: '/services/trailers-caravans-aframetowing',
            modifications: [
                {
                    title: 'Typical Modifications',
                    description: [
                        '“A” Frame Towing',
                        'Brake System Upgrades',
                        'Caravan GVM Upgrades',
                        'Caravan Modifications & Builds',
                        'Fifthwheel Trailer & Caravan Modifications',
                        'Trailer alterations and Modifications',
                        'Trailer GVM Upgrade',
                    ],
                },
            ],
        },
        {
            title: 'Defects Clearance',
            mainImg: defect,
            gallery: [defect1, defect2, defect3, defect4, defect5, defect6],
            url: '/services/defect-clerance',
            modifications: [
                {
                    title: '4WD Vehicles',
                    description: [
                        'Brake Systems',
                        'GVM Upgrades',
                        'Larger Wheels & Tyres',
                        'Suspension Lift',
                        'Turbo / Engine Upgrades',
                        'Twin Cab Conversions',
                    ],
                },
                {
                    title: 'Light Vehicles',
                    description: [
                        'Brake Systems',
                        'Exhaust Modifications',
                        'Suspension Modifications',
                        'Turbo / Engine Upgrades',
                        'Wheels & Tyres Modifications',
                    ],
                },
                {
                    title: 'Heavy Vehicles',
                    description: [
                        'Body Type Modifications',
                        'Braking Systems',
                        'Engine Modifications',
                        'GVM Modifications',
                        'Towing Equipment Modifications',
                        'Vehicle Construction',
                        'Wheelbase / Axle Configuration Modifications ',
                    ],
                },
                {
                    title: 'Trailers',
                    description: [
                        'Body Type Modifications',
                        'Braking Systems',
                        'Engine Modifications',
                        'Fifthwheel Trailer & Caravan Modifications',
                        'GVM Modifications',
                        'Towing Equipment Modifications',
                        'Vehicle Construction',
                        'Wheelbase / Axle Configuration Modifications ',
                    ],
                },
            ],
        },
    ]
}
