import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Wave2 from './svgs/Wave2'
import { fade, scrollReveal, titleAnim } from '../animation.js'
import { useScroll } from './useScroll.js'

const AboutSubSection = () => {
    const [element, controls] = useScroll()
    return (
        <About variants={scrollReveal} animate={controls} initial="hidden" ref={element}>
            <Detail>
                <Hide>
                    <motion.p variants={titleAnim}>
                        {/* <span>John</span> operates from a mobile workshop that comes to you for inspections or
                        at his private workshop, meeting all{' '}
                        <span>Vehicle Safety Compliance Scheme Standards </span> (VSCSS) & Heavy Vehicle
                        National Law standards and is covered by insurances as per State government
                        guidelines. */}

                        <span>John</span> operates from a mobile workshop that comes to you for inspections or can service your vehicle at his private workshop. {' '}
                        Meeting all <span>Vehicle Safety Compliance Scheme Standards</span> (VSCSS) and Heavy Vehicle National Law standards, John is fully covered by 
                        insurances as per State government guidelines. He is also equipped to conduct <span>vehicle emission testing</span>, ensuring your vehicle not only 
                        meets safety standards but also adheres to environmental regulations.
                    </motion.p>
                </Hide>
            </Detail>
            <SubDetail>
                <p>
                    With a background in manufacturing, product development and component compliance, John
                    have an extensive experience with a broad exposure to all aspects of industry.
                </p>
                <Link className="link" to={'/aboutus'}>
                    <Circle variants={fade}>
                        About Me <span> 🡧 </span>
                    </Circle>
                </Link>
            </SubDetail>
            <Wave2 />
        </About>
    )
}

const About = styled(motion.div)`
    display: grid;
    grid-template-areas:
        'detail detail  subdetail'
        'detail detail  right';
    gap: 0;
    padding: 5rem 10rem;
    margin: 0px 50px;
    box-shadow: 15px 15px #999999;
    background: white;
    border-radius: 5px;
    overflow: hidden;
    @media (max-width: 1300px) {
        grid-template-areas:
            'detail  detail'
            ' subdetail right';
    }
    @media (max-width: 1000px) {
        padding: 2rem 2rem;
        margin: 0px;
        grid-template-areas:
            'detail  detail'
            ' subdetail right';
    }
`
const Detail = styled(motion.div)`
    grid-area: detail;
    z-index: 2;
    width: 100%;
    p {
        font-size: 1.8rem;
        color: #000;
        overflow: hidden;
    }
    @media (max-width: 1300px) {
        display: flex;
        p {
            font-size: 1rem;
            width: 700px;
            word-wrap: break-word;
        }
    }
    @media (max-width: 700px) {
        p {
            width: 500px;
            font-size: 1rem;
        }
    }
    @media (max-width: 700px) {
        p {
            width: 400px;
        }
    }
    @media (max-width: 450px) {
        p {
            width: 300px;
        }
    }
`
const SubDetail = styled(motion.div)`
    grid-area: subdetail;
    display: flex;
    width: 60%;
    display: inline;
    justify-content: center;
    z-index: 2;
    .link {
        text-decoration: none;
    }
    p {
        color: black;
        font-size: 1.2rem;
    }
    @media (max-width: 1300px) {
        display: grid;
        grid-template-areas: 'paragraph paragraph paragraph circle';
        gap: 100px;
        padding: 3rem 5rem;
        margin: 0px 50px;
        p {
            margin: 0;
            grid-area: paragraph;
        }
    }
    @media (max-width: 600px) {
        margin: 0px 50px;
        grid-template-areas: 'paragraph paragraph paragraph circle';
        padding: 0;
        gap: 50px;
        p {
            width: 150px;
            font-size: 1rem;
            margin: 0;
            grid-area: paragraph;
        }
        span {
            display: none;
        }
    }
`

const Hide = styled.div`
    overflow: hidden;
`

const Circle = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 240px;
    width: 240px;
    font-size: 1.6rem;
    border-radius: 50%;
    background: #23d997;
    color: black;
    cursor: pointer;
    transition: all ease-in 1s;
    :hover {
        background: #34c1ed;
        color: white;
        transform: scale(1.1);
        box-shadow: 10px 10px #999999;
        text-decoration: underline;
    }
    span {
        position: absolute;
        font-size: 50px;
        z-index: 12;
        transform: translateX(200px) translateY(-150px);
    }
    @media (max-width: 1300px) {
        grid-area: circle;
    }
    @media (max-width: 680px) {
        height: 160px;
        width: 160px;
        span {
            transform: translateX(100px) translateY(-100px);
        }
    }
    @media (max-width: 450px) {
        font-size: 1rem;
        height: 100px;
        width: 100px;
    }
`

export default AboutSubSection
