import React from 'react'
import styled from 'styled-components'
//animation
import { motion } from 'framer-motion'
import { pageAnimation, titleAnim } from '../animation.js'
import Footer from '../components/Footer.js'
import { ServicesAreaSection } from '../components/ServiceAreaSection.js'
import ContactSection from '../components/ContactSection.js'

const ContactUs = () => {
    return (
        <>
            <ContactStyle variants={pageAnimation} initial="hidden" animate="show">
                <ContactSection />
                <ServicesAreaSection />
            </ContactStyle>
            <Footer />
        </>
    )
}

const ContactStyle = styled(motion.div)`
    padding: 5rem 10rem;
    color: #353535;
    min-height: 90vh;
    @media (max-width: 1300px) {
        padding: 2rem;
    }
`

const Title = styled.div`
    margin-bottom: 4rem;
    color: black;
    @media (max-width: 1300px) {
        h2 {
            font-size: 2rem;
        }
    }
`
const Hide = styled.div`
    overflow: hidden;
    h2 {
        color: white;
    }
`
const Circle = styled.div`
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    background: #fff;
    @media (max-width: 1300px) {
        width: 2rem;
        height: 2rem;
    }
`

const Social = styled(motion.div)`
    display: flex;
    align-items: center;
    h2 {
        margin: 2rem;
        color: #fff;
    }
    @media (max-width: 1300px) {
        h2 {
            font-size: 2rem;
        }
    }
`

export default ContactUs
