import React from 'react'
//Styles
import styled from 'styled-components'

//animation
import { motion } from 'framer-motion'
import { scrollReveal } from '../animation.js'
import { useScroll } from './useScroll.js'

export const ServicesDetailSection = () => {
    const [element, control] = useScroll()
    return (
        <Services
            variants={scrollReveal}
            animate={control}
            initial="hidden"
            viewport={{ once: true }}
            ref={element}
        >
            <SeviceDescription>
                <h2>
                    <span>About</span> Me
                </h2>
                <Details>
                    <p>
                        When talking to anybody about vehicle modifications John Oste Engineering is the
                        company that can provide the engineering support for your project needs.
                    </p>
                    <p>
                        With a background in manufacturing, product development and component compliance, I
                        have extensive experience with a broad exposure to all aspects of industry.
                    </p>
                    <p>
                        As a mechanical engineer, I provide vast experience in working with all makes and
                        models of vehicles, ranging from passenger cars and light commercial vehicles to heavy
                        trucks, trailers and caravans.
                    </p>
                    <p>
                        The VSCCS (Vehicle Safety Compliance Certification Scheme) licenses competent people
                        to inspect and certify compliance with the relevant NSW vehicle safety standards and
                        who can approve modifications under the conditions prescribed by the HVNL (Heavy
                        Vehicle National Law) for Non-Standard Vehicles and Heavy Vehicles.
                    </p>
                </Details>
            </SeviceDescription>
        </Services>
    )
}

const Services = styled(motion.div)`
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5rem 10rem;
    color: white;
    h2 {
        padding-bottom: 5rem;
    }

    @media (max-width: 1300px) {
        display: grid;
        grid-template-areas:
            'header header header header  '
            'cards cards cards cards'
            'image image image image';
        gap: 10px;
        padding: 2rem 2rem;
        text-align: center;
    }
`

const SeviceDescription = styled.div`
    flex: 1;
    padding-right: 0;
    h2 {
        font-weight: lighter;
    }
    @media (max-width: 1300px) {
        grid-area: header;
    }
`

const Details = styled.div`
    display: flex;
    flex-wrap: wrap;
    p {
        padding: 24px;
    }
    @media (max-width: 1300px) {
        justify-content: center;
    }
`
