import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
//animation
import { motion } from 'framer-motion'
import { pageAnimation } from '../animation.js'

import Slider from 'react-slick'
import Footer from '../components/Footer.js'

const settings = {
    className: 'slider',
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    infinite: true,
    pauseOnHover: false,
}

const ServiceDetail = ({ services, setServices }) => {
    const url = useLocation()
    const [service, setService] = useState(null)

    //UseEffect
    useEffect(() => {
        const currentService = services.filter((stateService) => stateService.url === url.pathname)
        if (currentService[0]) setService(currentService[0])
    }, [services, url])

    return (
        <>
            {service && (
                <Details variants={pageAnimation} initial="hidden" animate="show" exit="exit">
                    <Headline>
                        <h2>
                            {service.title}
                            <div className="line"></div>
                        </h2>

                        <img src={service.mainImg} alt="" />
                    </Headline>
                    <Mods>
                        {service.modifications.map((modification) => (
                            <Mod
                                title={modification.title}
                                descriptions={modification.description}
                                key={modification}
                            ></Mod>
                        ))}
                    </Mods>
                    <ImageDisplay>
                        <Slider {...settings}>
                            {service.gallery.map((img) => {
                                return (
                                    <div>
                                        <img src={img} alt="" />
                                    </div>
                                )
                            })}
                        </Slider>
                    </ImageDisplay>
                </Details>
            )}
            <Footer />
        </>
    )
}
// Award component
const Mod = ({ title, descriptions }) => {
    return (
        <ModificationStyle>
            <h3>{title}</h3>
            <div className="line"></div>
            <ul>
                {descriptions.map((description) => {
                    return (
                        <li key={description}>
                            <h4>{description}</h4>
                        </li>
                    )
                })}
            </ul>
        </ModificationStyle>
    )
}

// styles

const Details = styled(motion.div)`
    color: white;
`

const Headline = styled.div`
    min-height: 90vh;
    padding-top: 20vh;
    position: relative;
    .line {
        width: 100%;
        background: #23d997;
        height: 0.5rem;
    }
    h2 {
        position: absolute;
        font-size: 42px;
        top: 10%;
        left: 50%;
        transform: translate(-50%, 10%);
    }
    img {
        width: 100%;
        height: 70vh;
        object-fit: cover;
    }
    @media (max-width: 1250px) {
        h2 {
            font-size: 32px;
        }
    }
    @media (max-width: 950px) {
        h2 {
            font-size: 24px;
        }
    }
    @media (max-width: 710px) {
        h2 {
            font-size: 16px;
        }
    }
`
const Mods = styled.div`
    min-height: 80vh;
    display: flex;
    margin: 5rem 10rem;
    align-items: center;
    justify-content: space-around;
    @media (max-width: 1300px) {
        min-height: 50vh;
        flex-direction: column;
        margin: 2rem;
    }
`

const ModificationStyle = styled.div`
    padding: 0 2rem;
    h3 {
        font-size: 1.5rem;
    }
    .line {
        width: 80%;
        background: #23d997;
        height: 0.5rem;
        margin: 1rem 0rem;
    }
    h4 {
        font-size: 24px;
    }
    @media (max-width: 550px) {
        padding: 2rem 0rem;
    }
`

const ImageDisplay = styled.div`
    min-height: 50vh;
    .slick-slide img {
        margin: auto;
    }
    img {
        width: 80%;
        height: 80vh;
        object-fit: cover;
    }
    @media (max-width: 1300px) {
        img {
            height: 50vh;
        }
    }
`

export default ServiceDetail
