import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

const NavToggle = ({ children, title, linkTo, keyId }) => {
    const [toggle, setToggle] = useState(false)
    return (
        <motion.div
            onMouseEnter={() => {
                if (keyId === 2) {
                    setToggle(!toggle)
                }
            }}
            onMouseLeave={() => {
                if (keyId === 2) {
                    setToggle(!toggle)
                }
            }}
        >
            <Link className="link" to={linkTo}>
                <h5>{title}</h5>
            </Link>
            {toggle ? children : ''}
        </motion.div>
    )
}

export default NavToggle
