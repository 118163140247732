import React, { useState } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import NavToggle from './NavToggle'

//router
import { Link } from 'react-router-dom'

//animation
import { motion } from 'framer-motion'

const navItems = [
    {
        key: 1,
        title: 'About Me',
        linkTo: '/aboutus',
    },
    {
        key: 2,
        title: 'VSCCS Inspection ᐁ',
        linkTo: '/services',
    },
    {
        key: 3,
        title: 'Service Area',
        linkTo: '/service-area',
    },
    {
        key: 4,
        title: 'Contact Us',
        linkTo: '/contact',
    },
]

const Nav = () => {
    const { pathname } = useLocation()
    return (
        <StyedNav>
            <h1>
                <Link id="logo" to="/">
                    John Oste Engineering
                </Link>
            </h1>
            <ul>
                {navItems.map((navItem) => {
                    return (
                        <li key={navItem.key}>
                            <NavToggle title={navItem.title} linkTo={navItem.linkTo} keyId={navItem.key}>
                                <motion.ul className="navChild">
                                    <Link className="childLink" to="/services/heavy-vehicle">
                                        <li>Heavy Vehicles Over 4.5T GVM</li>
                                    </Link>
                                    <Link className="childLink" to="/services/light-vehicle">
                                        <li>Light Vehicles Under 4.5T GVM</li>
                                    </Link>
                                    <Link
                                        className="childLink"
                                        to="/services/campervan-motorhome-conversions"
                                    >
                                        <li>Campervan / Motorhome Conversions</li>
                                    </Link>
                                    <Link className="childLink" to="/services/trailers-caravans-aframetowing">
                                        <li>Trailers / Caravans / ‘’A” Frame Towing</li>
                                    </Link>
                                    <Link className="childLink" to="/services/defect-clerance">
                                        <li>Defects Clearance</li>
                                    </Link>
                                </motion.ul>
                            </NavToggle>

                            <Line
                                transition={{ duration: 0.75 }}
                                initial={{ width: '0%' }}
                                animate={{ width: pathname === navItem.linkTo ? '50%' : '0%' }}
                            />
                        </li>
                    )
                })}
            </ul>
        </StyedNav>
    )
}

const StyedNav = styled.nav`
    min-height: 10vh;
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 10rem;
    background: #5387b8;
    position: sticky;
    top: 0;
    z-index: 10;
    .link {
        color: white;
        text-decoration: none;
    }
    ul {
        display: flex;
        list-style: none;
    }
    #logo {
        font-size: 2.5rem;
        font-family: 'Nunito', sans-serif;
        color: white;
        font-weight: lighter;
        text-decoration: none;
    }

    li {
        padding-left: 5rem;
        position: relative;
        .navChild {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            padding: 10px;
            width: 400px;
            position: absolute;
            text-decoration: none;
            z-index: 1;
            transition: all 0.5s ease-in;

            li {
                display: block;
                margin: 5px;
                padding: 1.5rem 0.5rem;
                background: #fff;
                background: #3d6386;
                border-radius: 6px;
                text-decoration: none;
                :hover {
                    background: #ff865e;
                }
            }
        }
        .childLink {
            text-align: center;
            color: white;
            text-decoration: none;
        }
    }

    @media (max-width: 1300px) {
        flex-direction: column;
        padding: 1rem;
        #logo {
            display: inline-block;
            margin: 1rem;
        }
        ul {
            padding: 2rem;
            justify-content: space-around;
            width: 100%;
        }
        li {
            padding: 0;
        }
    }
    @media (max-width: 600px) {
        padding: 1rem;
        #logo {
            font-size: larger;
            margin: 1rem;
        }
        ul {
            padding: 2rem;
            justify-content: space-around;
            width: 100%;
        }
        li {
            font-size: 1rem;
            padding: 0;
        }
    }
    @media (max-width: 470px) {
        li {
            font-size: 1rem;
        }
    }
`
const Line = styled(motion.div)`
    position: 5%;
    height: 0.3rem;
    background: #23d997;
    width: 0%;
    bottom: -80%;
    left: 60%;
`

export default Nav
