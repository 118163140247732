import { createGlobalStyle } from 'styled-components'

const offWhite = '#EEF1F4'

const GlobalStyle = createGlobalStyle`
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}
html{
    @media (max-width: 1700px) {
        font-size: 80%;
    }
}

body{
    background: #2e2e2e;
    overflow-x: hidden;
}

button{
    font-weight: bold;
    font-size: 1.1rem;
    cursor: pointer;
    padding: 1rem 2rem;
    border: 3px solid #EEF1F4;
    border-radius: 6px;
    background: #34C1ED;
    color: #FEF9EF;
    transition: all 0.5s ease-in;
    &:hover {
        background: #ce4a1e;
    }
}

h2{
    font-weight: lighter;
    font-size: 4rem;
}

h3{
    color:white;
}

h4{
    font-weight:bold;
    font-size: 2rem;
}

a{
    font-size: 1.1rem;
}

span{
    font-weight: bold;
    color:#23d997;
}

p{
    padding: 3rem 0rem;
    color: #FEF9EF;
    font-size: 1.4rem;
    line-height: 150%;
    text-shadow: 0.5px 1px #000000;
}
`
export default GlobalStyle
