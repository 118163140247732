import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { scrollReveal, titleAnim, fade, lineAnim } from '../animation.js'
import { useScroll } from './useScroll.js'
export const ContactSection = () => {
    const [element, controls] = useScroll()
    return (
        <Contact variants={scrollReveal} animate={controls} initial="hidden" ref={element}>
            <Details variants={titleAnim} initial="hidden" animate="show">
                <h1>Give me a </h1>
                <h1 className="indent">
                    <span>Call</span> today
                </h1>

                <Line variants={lineAnim}>
                    <Link className="link" to={'/contact'}>
                        <Circle>Get In Touch</Circle>
                    </Link>
                </Line>
                <StyledButton>
                    <a href="mailto:JohnOste@live.com.au">
                        {' '}
                        <motion.button variants={fade}>JohnOste@live.com.au</motion.button>
                    </a>
                    <a href="tel:+61439440203">
                        {' '}
                        <motion.button className="btn-2" variants={fade}>
                            +61 439 440 203
                        </motion.button>
                    </a>
                </StyledButton>
            </Details>
        </Contact>
    )
}

const Contact = styled(motion.div)`
    height: 100vh;
    padding: 5rem 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 750px) {
        padding: 2rem;
    }
`

const Details = styled(motion.div)`
    color: white;
    width: 100%;
    .indent {
        padding: 0 10rem;
    }
    h1 {
        font-size: 80px;
    }
    button {
        margin: 2rem;
        z-index: 1;
    }
    @media (max-width: 1200px) {
        .indent {
            padding: 0 5rem;
        }
        h1 {
            font-size: 40px;
        }
    }
    @media (max-width: 750px) {
        .indent {
            padding: 0 2rem;
        }
        h1 {
            font-size: 20px;
        }
        button {
            margin: 2rem 0;
        }
    }
`
const Line = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 13rem;
    background: #0a89ff;
    box-shadow: 1px 1px white;
    height: 0.2rem;
    margin: 2rem 0rem;
    width: 100%;
    .link {
        color: white;
        text-decoration: none;
    }
    @media (max-width: 1200px) {
        padding: 0rem 3rem;
    }
    @media (max-width: 600px) {
        padding: 0rem;
        margin-top: 70px;
    }
`

const StyledButton = styled.div`
    display: block;
    margin-top: 5rem;
    .btn-2 {
        background: #ce4a1e;
        &:hover {
            background: #34c1ed;
        }
    }
`

const Circle = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 240px;
    width: 240px;
    font-size: 1.6rem;
    font-weight: 800;
    border-radius: 50%;
    background: #0a89ff;
    color: white;
    cursor: pointer;
    transition: all ease-out 1s;
    animation: scale2 4s ease infinite;
    :hover {
        background: #fff;
        color: black;
        text-shadow: 25px 25px #0a89ff;
        box-shadow: -10px -10px #0a89ff;
    }
    @media (max-width: 1200px) {
        height: 160px;
        width: 160px;
    }
    @media (max-width: 750px) {
        height: 100px;
        width: 100px;
        font-size: 1rem;
    }

    @keyframes scale2 {
        0% {
            transform: scale(1.2);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.2);
        }
    }
`
export default ContactSection
